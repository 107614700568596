import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Button, ButtonGroup, Container, Grid, TextField } from '@material-ui/core';

function App() {
    return (
        <Container maxWidth="sm" id="main">
            <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
                <Grid container item xs={12} spacing={1} direction="row">
                    <Grid item xs={4}><TextField id="name" label="exercise" variant="outlined" /></Grid>
                    <Grid item xs={4}><TextField id="input" label="interval" variant="outlined" /></Grid>
                    <Grid item xs={4}><TextField id="listname" label="listname" variant="outlined" /></Grid>
                </Grid>
                <Grid container spacing={1} item xs={12} sm={12}>
                    <Grid item xs={4} sm={4}><Button variant="outlined" color="default" id="set-btn" onClick={addTimer}>add</Button></Grid>
                    <Grid item xs={4} sm={4}><Button color="primary" variant="outlined" onClick={startTimer}>Start</Button></Grid>
                    <Grid item xs={4} sm={4}><Button color="secondary" variant="outlined" id="set-btn" onClick={clearTimer}>Clear</Button></Grid>
                </Grid>
                <Grid container spacing={1} direction="row" item xs={12}>
                    <Grid item xs={6} sm={6}><Button variant="outlined" onClick={viewList}>View List</Button></Grid>
                    <Grid item xs={6} sm={6}><Button variant="outlined" onClick={saveList}>Save List</Button></Grid>
                </Grid>
                <Grid container spacing={1} direction="row" item xs={12}>
                    <Grid item xs={6}><Button variant="outlined" color="default" onClick={importData}>Import</Button></Grid>
                    <Grid item xs={6}><Button variant="outlined" color="default" onClick={exportData}>Export</Button></Grid>
                </Grid>
                <div id="list"></div>
            </Grid>
            <Grid id="display"></Grid>
        </Container >
    );
}

function importData() {

}

function exportData() {

}

let itval = null;
function tick(name, t) {
    let bg = 'bg-blue';
    if (name === 'Rest') {
        bg = 'bg-teal';
    }
    const element = (
        <div className={bg}>
            <h1 className="exercise">{name}</h1>
            <h1 className="number">{t}</h1>
        </div>
    );
    ReactDOM.render(element, document.getElementById('display'));
}

function startTimer() {
    let i = 0;
    let saved = JSON.parse(localStorage.getItem('saved'));
    if (saved)
        timer(saved, i);
}

function timer(saved, i = 0) {
    clearInterval(itval);
    let name = saved[i].name;
    let t = saved[i].time;
    itval = setInterval(() => {
        t = t - 1;
        tick(name, t);
        if (t <= 0) {
            clearInterval(itval);
            if (i + 1 < saved.length) {
                timer(i + 1);
            } else {
                tick('End', 'Great!');
            }
        };
    }, 1000);
}

function showTimer() {
    let saved = localStorage.getItem('saved');
    if (saved) {
        saved = JSON.parse(saved);
        ReactDOM.render(
            <ul>{saved.map((li, index) => (<li key={index}>{li.name},{li.time}</li>))}
            </ul>,
            document.getElementById('list')
        )
    }
}

function addTimer() {
    let name = document.getElementById('name').value;
    let t = parseInt(document.getElementById('input').value);
    let saved = localStorage.getItem('saved');
    if (!saved) {
        saved = [{ 'name': name, 'time': t }];
    } else {
        saved = JSON.parse(saved);
        saved.push({ 'name': name, 'time': t });
    }
    localStorage.setItem('saved', JSON.stringify(saved));
    showTimer();
}

function clearTimer() {
    localStorage.removeItem('saved');
}

function saveList() {
    let listname = document.getElementById('listname').value;
    let saved = JSON.parse(localStorage.getItem('saved'));
    let list = JSON.parse(localStorage.getItem('list'));
    if (!saved) return;
    if (!list) {
        list = [{ 'listname': listname, 'data': saved }];
    } else {
        list.push({ 'listname': listname, 'data': saved });
    }
    localStorage.setItem('list', JSON.stringify(list));
}

function viewList() {
    let lists = JSON.parse(localStorage.getItem('list'));
    const elem = (
        <div>
            {lists.map((li, index) =>
            (<li key={index}>{li.listname} {li.data.map((e, index) =>
                (<ul key={index}>{e.name},{e.time}</ul>))} </li>))}
        </div>
    );
    ReactDOM.render(
        elem,
        document.getElementById('list')
    );
}

window.onload = () => {
    document.getElementById('input').addEventListener('keyup', (e) => {
        if (e.key === 'Enter') {
            addTimer();
        }
    });
    document.getElementById('name').addEventListener('keyup', (e) => {
        if (e.key === 'Enter') {
            addTimer();
        }
    });
    document.getElementById('listname').addEventListener('keyup', (e) => {
        if (e.key === 'Enter') {
            saveList();
        }
    });
    showTimer();
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
